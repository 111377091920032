import { conf } from "config/env";
import * as authMapper from "mappers/auth";

export const login = async ({ email, password }) => {
  let response;
  try {
    response = await fetch(`${conf.api}/v1/users/login`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        user: {
          email: String(email).trim(),
          password: String(password).trim(),
        },
      }),
    });
    if (!response.ok) throw new Error("http response not ok");
    const rawResponse = await response.json();
    return authMapper.login.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    if (response?.status === 401) return { error: "invalidCredentials" };
    return { error: "unknown" };
  }
};

export const requestPasswordReset = async ({ email }) => {
  let response;
  try {
    response = await fetch(
      `${conf.api}/v1/users/password`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": 'application/json',
        },
        credentials: "include",
        body: JSON.stringify({
          email: String(email).trim(),
        }),
      }
    );
    if (!response.ok) throw new Error("http response not ok");
    const rawResponse = await response.json();
    return authMapper.requestPasswordReset.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    if (response?.status === 401) return { error: "invalidCredentials" };
    return { error: "unknown" };
  }
};
